import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { theme } from '../styles';
import { SEO } from '../components';
import website from '../../config/website';

const StyledNotFound = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  background-color: ${props => props.theme.colors.greyLight};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 1rem;
  padding-bottom: 4rem;
`;

const StyledNotFoundWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 60px;
  left: 60px;

  h1 {
    font-size: 6rem;
    color: ${props => props.theme.colors.white};
  }

  p {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    color: ${props => props.theme.colors.white};

    @media (max-width: 700px) {
      font-size: 1.6rem;
    }
  }

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
  }

  @media (max-width: 700px) {
    max-width: 350px;
    text-align: center;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const NotFound = ({ data: { prismicNotFound, notfound } }) => {
  const { data } = notfound;

  return (
    <ThemeProvider theme={theme}>
      <StyledNotFound style={{ backgroundImage: `url(${data.image.url})` }}>
        <SEO title={`404 | ${website.title}`} />
        <StyledNotFoundWrapper>
          <h1>{data.title.text}</h1>
          <p dangerouslySetInnerHTML={{ __html: data.content.html }} />
        </StyledNotFoundWrapper>
      </StyledNotFound>
    </ThemeProvider>
  );
};

export default NotFound;

NotFound.propTypes = {
  data: PropTypes.shape({
    notfound: PropTypes.object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query NotFoundQuery {
    notfound: prismicNotfound {
      data {
        title {
          text
        }
        content {
          html
        }
        image {
          url
        }
      }
    }
  }
`;
